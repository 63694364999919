<template>
<svg :style="classStyle">
    <path stroke="grey" fill="none" stroke-width="1.2" :d="cubic_curve"></path>
</svg>
</template>

<script>
export default {
    name: 'cubic-curve',
    props: ['item1', 'item2'],
    data: function () {
        return {
            position1: null,
            position2: null,
        }
    },
    beforeCreate () {
        this.emitter.on(this.item1, position1 => {
            this.position1 = position1
        });
        this.emitter.on(this.item2, position2 => {
            this.position2 = position2
        });
    },
    computed: {
        cubic_curve: function() {
            if (this.position1 && this.position2) {
                let x1 = this.position1.x + this.position1.width / 2 + window.scrollX
                let x2 = this.position2.x + this.position2.width / 2 + window.scrollX
                let y1 = this.position1.y + this.position1.height / 2 + window.scrollY
                let y2 = this.position2.y + this.position2.height / 2 + window.scrollY
                let cx = Math.abs(x1 - x2) / 2;
                let cx1 = 0;
                let cx2 = 0;
                if (x1 < x2) {
                    cx1 = x1 + cx;
                    cx2 = x2 - cx;
                } else if (x1 > x2) {
                    cx1 = x1 - cx;
                    cx2 = x2 + cx;
                } else {
                    cx1 = x1;
                    cx2 = x2;
                }
                return `M${x1},${y1} C${cx1},${y1} ${cx2},${y2} ${x2},${y2}`
            }
            return ""
        },
        classStyle: function() {
            if (this.position1 && this.position2) {
                if (this.position2.y > this.position1.y) {
                    return `
                    height: ${this.position2.y + this.position2.height / 2 + window.pageYOffset};
                    `
                } else {
                    return `
                    height: ${this.position1.y + this.position1.height / 2 + window.pageYOffset};
                    `
                }
            }
            return 0
        }
    }
}
</script>

<style scope>
svg {
    position: absolute;
    width: 1px;
    height: 1px;
    right: 0;
    left: 0;
    top: 0;
    /* bottom: 0; */
    z-index: 0;
    overflow: visible;
}
</style>
