<template>
<div class="space"></div>
<div class="list" v-if="resources.length">
    <p>Показать покрытие</p>
    <list-resource-item
    v-for="(resource, index) in resources"
    :title="resource"
    :color="palette[index]"
    :key="'resource' + index">
    </list-resource-item>
</div>
</template>

<script>
import ListResourceItem from '@/components/ListResourceItem.vue'

export default {
  name: 'list-resources',
  props: ['resources'],
  components: {
      ListResourceItem
  },
  data: function () {
      return {
          palette: [
              'rgb(255, 132, 132)',
              'rgb(255, 197, 90)',
              'rgb(88, 156, 88)',
              'rgb(255, 255, 129)',
              'rgb(161, 230, 230)',
              'rgb(231, 151, 231)'
          ]
      }
  }
}
</script>

<style scoped>
@import '../assets/constants.css';
.space {
    min-width: 200px;
}
.list {
    background: var(--dark-background-color);
    flex-grow: 1;
    position: fixed;
    right: 5px;
    width: 200px;
    top: calc(50% - 150px);
    border: 1px solid var(--border-color);
    /* border-radius: 10px 0 0 10px; */
    border-radius: 10px;
}
.list > p {
    color: whitesmoke;
}
</style>
