<template>
<div class="mark" :style="'background-color: ' + color + ';'"></div>
</template>

<script>
export default {
    props: ['color']
}
</script>

<style scoped>
.mark {
    width: 10px;
    height: 3px;
    border-radius: 10px;
    margin-left: 1px;
    margin-right: 1px;
}
</style>