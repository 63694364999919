<template>
  <div class="about content">
    <h1>Информация о Yollection</h1>
    <div class="container">
      <div class="words">
        <p>
          Yollection –– это словослияние "Your" и "collection", что означает "Твоя коллеция". Но так же можно
          интерпритировать и как "Your lection", что будет обозначать "Твоя лекция", процесс обучения.
          Yollection декомпозирует такую сложную задачу как освоение професси на множество подазач,
          благодаря которым вам будет легко двигаться к намеченной цели. В Yollection вы можете выбрать целую профессию
          (например, <a href="/road/backend">backend-разработчик</a>) или знание (например,
          <a href="/road/python">Python</a>), навык. Сравнить обучающие курсы или другие ресурсы относительно покрытия
          выбранной карты и выбрать путь обучения.
        </p>
        <p>
          Мы открыты к идеям и предложениям. Вы можете отправить вашу идею на
          <a href="mailto:idea@yollection.ru">idea@yollection.ru</a>.
        </p>
      </div>
      <div class="img">
        <img src="@/assets/about-img.jpg">
      </div>
    </div>
    <p>Россия, Санкт-Петербург, 2021 –– {{ this.now_year }}</p>
  </div>
</template>

<script>
export default {
  name: 'about',
  computed: {
    now_year: function() {
      return new Date().getFullYear()
    }
  }
}
</script>
<style scoped>
.container {
  display: flex;
  gap: 30px;
  margin-bottom: 150px;
  flex-wrap: wrap;
}
.img {
  flex-grow: 1;
  width: 300px;
  display: flex;
  align-items: center;
}
.img > img {
  object-fit: cover;
  width: 100%;
}
.words {
  flex-grow: 1;
  width: 300px;
  text-align: left;
  font-size: 20px;
}
.words a {
  color: var(--light-color-selection);
  text-decoration: none;
}
.words a:hover {
  text-decoration: underline;
}
.about > h1 {
  text-align: left;
  color: var(--light-color-selection);
}
</style>
