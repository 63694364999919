<template>
<div class="box">
    <input type="checkbox" :id="title" :onchange="show_related"/>
    <label :for="title">
        <div class="check" :style="style"/>
        <span>{{ title }}</span>
    </label>
</div>
</template>

<script>
export default {
    props: ['title', 'color'],
    name: 'list-resource-item',
    data: function () {
        return {
            style: ''
        }
    },
    methods: {
        show_related: function() {
            if (this.style == '') {
                this.style = 'background-color: ' + this.color + ';'
            } else {
                this.style = ''
            }
            this.emitter.emit('show_resource', {title: this.title, color: this.color})
        }
    }
}
</script>

<style scoped>
@import '../assets/constants.css';

label {
  display: flex;
  flex-direction: row;
}

.box {
  margin: 10px;
  border: 1px solid var(--border-color);
  padding: 8px;
  border-radius: 10px;
  background: var(--light-background-color);
}

.box > input {
  display: none;
}

.box .check {
  margin-top: 1px;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 1px solid gray;
}

.box span {
  margin-left: 20px;
}

</style>
