<template>
<div class="row-tails">
    <block-item
      v-if="row[0].id"
      :block="row[0]"
      :index="0"
      :id="'block' + row[0].id"
      :redirect="redirect_blocks"
    ></block-item>
    <chapter-item
      v-if="row[1].id"
      :chapter="row[1]"
      :id="'chapter' + row[1].id"
    ></chapter-item>
    <block-item
      v-if="row[2].id"
      :block="row[2]"
      :index="1"
      :id="'block' + row[2].id"
      :redirect="redirect_blocks"
    ></block-item>
</div>
    <cubic-curve
      :item1="'chapter' + row[0].chapter"
      :item2="'block' + row[0].id"
    ></cubic-curve>
    <cubic-curve
      :item1="'chapter' + row[2].chapter"
      :item2="'block' + row[2].id"
    ></cubic-curve>
</template>

<script>
import ChapterItem from './ChapterItem.vue'
import BlockItem from './BlockItem.vue'
import CubicCurve from './CubicCurve.vue'

export default {
    name: 'row-tails',
    props: ['row', 'index', 'redirect_blocks'],
    components: {
        ChapterItem,
        BlockItem,
        CubicCurve
    }
}
</script>

<style scoped>
.row-tails {
  position: relative;
  display: flex;
  padding: 0;
  margin: 0;
  gap: 100px;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
  align-items: center;
}
</style>