<template>
<p>resource</p>
</template>

<script>
import SiteHeader from '@/components/SiteHeader.vue'

export default {
    components: {
        SiteHeader,
    }
}
</script>

<style scoped>

</style>
