<template>
<div class="content">
    <div class="block-header">
        <div v-if="parent_map" class="breadcrumbs">
            <router-link :to="parent_map.url">{{ parent_map.title }}</router-link>
            <p>/</p>
            <h1>{{ title }}</h1>
        </div>
        <p>{{ description }}</p>
        <router-link v-if="related_direction" :to="'/road/' + related_direction + '/'">
            <button class="button">Подробная карта изучения</button>
        </router-link>
    </div>
    <div class="resources">
        <div class="resource-block">
            <h2 class="resources-header">Подтемы</h2>
            <div class="resource-list">
                <div class="subblock" v-for="subblock in subblocks" :key="'subblock' + subblock.id">
                    {{ subblock.title }}
                </div>
            </div>
        </div>
        <div class="resource-block">
            <h2 class="resources-header">Курсы</h2>
            <div class="resource-list">
                <!-- <router-link v-for="course in resources" :to="course.url" :key="'course' + course.id">
                    <button class="resource">{{ course.title }}</button>
                </router-link> -->
                <div class="subblock" v-for="course in resources" :to="course.url" :key="'course' + course.id">
                    {{ course.source.title }}
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import SiteHeader from '@/components/SiteHeader.vue'
import router from '@/router/index.js'

export default {
    components: {
        SiteHeader
    },
    data: function () {
        return {
            title: '',
            description: '',
            subblocks: [],
            resources: [],
            parent_map: null,
            related_direction: null
        }
    },
    mounted() {
        this.axios.get(`${this.site_url}/api/blocks/${this.$route.params.slug}/info`).then(response => {
            this.title = response.data.title
            this.description = response.data.description
            this.subblocks = response.data.subblocks
            this.resources = response.data.resources
            this.related_direction = response.data.related_direction
        }).catch(err => {
            router.push({name: 'page404', params: err})
        })
        this.axios.get(`${this.site_url}/api/directions/${this.$route.query.parent_map}`).then(response => {
            this.parent_map = {
                url: '/road/' + response.data.slug,
                title: response.data.title
            }
        }).catch(err => {
            router.push({name: 'page404', params: err})
        })
    }
}
</script>

<style scoped>
.breadcrumbs {
    display: flex;
    width: 100%;
}
.breadcrumbs > * {
    margin: 0;
    padding: 0;
    font-size: 30px;
    color: var(--dark-color);
}
.breadcrumbs > a {
    text-decoration: none;
}
.breadcrumbs > a:hover {
    color: var(--light-color-selection);
}
.block-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
}
.button {
  background-color: var(--light-color-selection);
  border: 1px solid var(--light-color-selection);
  color: var(--light-color);
  border-radius: 10px;
  padding: 14px;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.2s ease-out;
  margin-bottom: 30px;
}
.button:hover {
  background-color: var(--light-color);
  color: var(--dark-color);
}
.resources {
    width: 100%;
    display: flex;
    gap: 10px;
}
.resources-header {
  width: 100%;
  text-align: left;
  margin: 0;
  color: white;
}
.resource-block {
  margin-bottom: 10px;
  background-color: var(--light-color-selection);
  border-radius: 10px;
  padding: 20px;
  flex-grow: 1;
  flex-basis: 200px;
}
.resource-block:nth-child(even) {
    background-color: var(--dark-color);
}
.resource-list {
  display: flex;
  gap: 5px;
  margin-top: 30px;
}
.resource, .subblock {
  padding: 14px;
  border-radius: 10px;
  border: 1px solid var(--dark-color);
  transition: all 0.2s ease-out;
  cursor: pointer;
  font-size: 16px;
  background-color: var(--light-color-selection);
  color: var(--dark-color);
  margin: 0;
}
.subblock {
    cursor: unset;
}
.resource:hover {
  background-color: whitesmoke;
}
</style>
