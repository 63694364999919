<template>
<div class="content">
    <h1>{{ title }}</h1>
    <p>{{ description }}</p>
    <router-link :to="'/road/' + $route.params.slug">Карта</router-link>
</div>
</template>

<script>
import SiteHeader from '@/components/SiteHeader.vue'
import router from "@/router/index.js"

export default {
    components: {
        SiteHeader,
    },
    data: function () {
        return {
            title: '',
            description: ''
        }
    },
    mounted() {
        this.axios.get(`${this.site_url}/api/directions/${this.$route.params.slug}`).then(response => {
            this.title = response.data.title
            this.description = response.data.description
        }).catch(err => {
            router.push({name: 'page404', params: err})
        })
    }
}
</script>

<style scoped>

</style>
