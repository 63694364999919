<template>
<div class="content">
    <h1 class="title">Профессии</h1>
    <div class="container">
    <div class="profession-block"
        v-for="(profession, index) in items"
        :key="'professions' + index"
    >
    <h2>{{ profession.title }}</h2>
    <p>{{ profession.description }}</p>
        <tail-grid
        :type="'direction'"
        :root_path="'professions/' + profession.slug + '/'"
        ></tail-grid>
    </div>
  </div>
</div>
</template>

<script>
import TailGrid from '@/components/TailGrid.vue'
export default {
  components: { TailGrid },
  props: ['title'],
  data: function() {
    return {
      items: []
    }
  },
  mounted() {
    this.axios.get(`${this.site_url}/api/professions/`).then(response => {
      this.items = response.data
    })
  }
}
</script>

<style scoped>
.title {
  text-align: left;
  color: var(--light-color-selection);
}
.container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.profession-block > h2, .profession-block > p {
  text-align: left;
}
.profession-block {
  width: 100%;
  margin-bottom: 50px;
}
.profession-block >>> a:hover {
    border-color: var(--light-color-selection);
}
</style>
