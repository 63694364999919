<template>
<div class="roadmap" v-if="allowed_space">
  <row-tails
    v-for="(row, index) in tails"
    :key="'chapter-area' + index"
    :row="row"
    :index="index"
    :redirect_blocks="'block'"
  ></row-tails>
</div>
<div class="collapsed-map" v-else>
  <p>Если вы с мобильного устройства, поверните его, чтобы увидеть карту</p>
  <collapsed-chapter
    v-for="(chapter, index) in collapsed_tails"
    :key="'collapsed-chapter' + index"
    :chapter="chapter"
    :redirect_blocks="'block'"
  ></collapsed-chapter>
</div>
</template>

<script>
import CollapsedChapter from '@/components/CollapsedChapter.vue'
import RowTails from '@/components/RowTails.vue'

export default {
  name: 'road-map',
  props: ['tails', 'collapsed_tails'],
  data: function () {
    return {
      allowed_space: true
    }
  },
  components: {
    RowTails,
    CollapsedChapter
  },
  methods: {
    checkAllowedSpace: function () {
      if (window.innerWidth > 660) {
        this.allowed_space = true
      } else {
        this.allowed_space = false
      }
    }
  },
  mounted() {
    this.checkAllowedSpace()
  },
  created() {
      window.addEventListener('resize', this.checkAllowedSpace);
  },
  unmounted() {
      window.removeEventListener('resize', this.checkAllowedSpace);
  },
}
</script>

<style scoped>
.roadmap {
  flex-grow: 2;
}
.collapsed-map {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: whitesmoke;
}
</style>
