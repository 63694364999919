<template>
    <div class="road content">
        <div class="entites">
            <h1>{{ map_title }}</h1>
            <p>{{ map_description }}</p>
        </div>
    </div>
<div>
    <div class="content">
        <div class="road-area">
            <div class="space" v-if="allowed_space"></div>
            <road-map :tails="tails" :collapsed_tails="collapsed_tails"></road-map>
            <list-resources :resources="resources" v-if="allowed_space"></list-resources>
        </div>
    </div>
</div>
</template>

<script>
import RoadMap from '@/components/RoadMap.vue'
import SiteHeader from '@/components/SiteHeader.vue'
import ListResources from '@/components/ListResources.vue'
import router from "@/router/index.js"

export default {
  name: 'RoadPage',
  components: {
    RoadMap,
    SiteHeader,
    ListResources
  },
  data: function () {
    return {
      tails_: [],
      collapsed_tails_: [],
      map_title: '',
      map_description: '',
      resources: [],
      allowed_space: true
    }
  },
  methods: {
        convert_tails: function(tails) {
            let new_tails = []
            for (let i = 0; i < tails.length; i++) {
                let middle = Math.floor(tails[i]['blocks'].length / 2)
                for (let j = 0; j < tails[i]['blocks'].length; j++) {
                    let row = []
                    row.push({
                        id: tails[i]['blocks'][j].id,
                        title: tails[i]['blocks'][j].title,
                        chapter: tails[i]['blocks'][j].chapter,
                        resources: tails[i]['blocks'][j].resources,
                        slug: tails[i]['blocks'][j].slug
                    })
                    if (j == middle || j+1 == middle) {
                        row.push({
                            id: tails[i].id,
                            title: tails[i].title
                        })
                    }
                    else {
                        row.push({})
                    }
                    if (tails[i]['blocks'][++j]) {
                        row.push({
                            id: tails[i]['blocks'][j].id,
                            title: tails[i]['blocks'][j].title,
                            chapter: tails[i]['blocks'][j].chapter,
                            resources: tails[i]['blocks'][j].resources,
                            slug: tails[i]['blocks'][j].slug
                        })
                    } else {
                        row.push({})
                    }
                    new_tails.push(row)
                }
                new_tails.push([{}, {}, {}])
            }
            return new_tails
        },
        checkAllowedSpace: function () {
            if (window.innerWidth > 1000) {
                this.allowed_space = true
            } else {
                this.allowed_space = false
            }
        }
    },
    computed: {
        tails: function () {
            return this.tails_
        },
        collapsed_tails: function () {
            return this.collapsed_tails_
        }
    },
    mounted() {
        this.axios.get(`${this.site_url}/api/directions/${this.$route.params.slug}`).then(response => {
            this.tails_ = this.convert_tails(response.data.chapters)
            this.collapsed_tails_ = response.data.chapters
            this.resources = response.data.resources
            this.map_title = response.data.title
            this.map_description = response.data.description
        }).catch(err => {
            router.push({name: 'page404', params: err})
        })
        this.checkAllowedSpace()
    },
    created() {
        window.addEventListener('resize', this.checkAllowedSpace);
    },
    unmounted() {
        window.removeEventListener('resize', this.checkAllowedSpace);
    },
}
</script>

<style>
.space {
    flex-grow: 1;
    height: 20px;
    width: 200px;
}
.road-area {
    display: flex;
    gap: 50px;
    align-items: flex-start;
    justify-content: center;
}
.entites {
    /* border-bottom: 1px solid black; */
    width: 80%;
    margin-left: 10%;
    color: var(--dark-background-color);
}
</style>
