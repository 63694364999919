<template>
<h1>Blank page for test components</h1>
</template>

<script>

export default {
  components: {

  },
  name: 'blank',
  data: function() {
    return {
      dd: null
    }
  },
}
</script>

<style scoped>

</style>