<template>
  <metainfo>
    <meta name="robots" content="noindex">
  </metainfo>
  <div class="content">
    <h1>Ошибка 404</h1>
    <p>Запрашиваемая страница не найдена</p>
  </div>
</template>

<script>
import SiteHeader from '@/components/SiteHeader.vue'

export default {
  components: {
    SiteHeader
  },
  name: 'page404'
}
</script>
