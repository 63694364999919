<template>
<router-link
  class="block"
  :to="'/'+redirect+'/'+block.slug+'?parent_map='+this.$route.params.slug"
  :id="'block' + block.id"
  :style="classStyle"
>
<div>{{ block.title }}</div>
<div class="marks">
    <resource-mark
    v-for="(mark, index) in resource_marks"
    :color="mark.color"
    :key="'resource_mark' + index"
    ></resource-mark>
</div></router-link>
</template>

<script>
import ResourceMark from '@/components/ResourceMark.vue'

export default {
    name: 'block-item',
    props: ['block', 'index', 'redirect'],
    components: {
        ResourceMark
    },
    methods: {
        updatePosition() {
            let elem = document.querySelector('#block' + this.block.id).getBoundingClientRect()
            this.emitter.emit('block' + this.block.id, elem)
        }
    },
    data: function() {
        return {
            style: '',
            color: '',
            active: [],
            updated: false,
            resource_marks: []
        }
    },
    beforeCreate() {
        if (this.redirect == 'block') {
            this.emitter.on('show_resource', resource => {
                if (this.block.resources.includes(resource.title)) {
                    if (this.active.includes(resource.title)) {
                        this.color = ''
                        const index = this.active.indexOf(resource.title);
                        if (index > -1) {
                            this.active.splice(index, 1);
                            this.resource_marks.splice(index, 1);
                        }
                    } else {
                        // this.color = 'box-shadow: 0 0 5px rgb(230, 122, 0);'
                        this.active.push(resource.title)
                        this.resource_marks.push({color: resource.color})
                    }
                }
            });
        }
    },
    created() {
        window.addEventListener('resize', this.updatePosition);
    },
    unmounted() {
        window.removeEventListener('resize', this.updatePosition);
    },
    mounted() {
        this.updatePosition()
    },
    updated() {
        if (this.updated == false) {
            this.updated = true
            this.updatePosition()
        }
    },
    computed: {
        classStyle: function () {
            return this.style
        }
    }
}
</script>

<style scoped>
@import '../assets/constants.css';

.block {
    color: var(--dark-background-color);
    padding: 10px;
    border: 1px solid var(--light-color-selection);
    border-radius: 10px;
    background-color: whitesmoke;
    text-decoration: none;
    transition: all 0.2s ease-out;
    z-index: 1;
}
.block:hover {
    /* color: white; */
    border-color: var(--dark-background-color);
    /* border-color: white; */
}
.marks {
    display: flex;
    position: absolute;
}
</style>
