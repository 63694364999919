<template>
    <div :style="classStyle"
         :id="'chapter' + chapter.id"
    >{{ chapter.title }}</div>
</template>

<script>
export default {
    name: 'chapter-item',
    props: ['chapter', 'index'],
    methods: {
        updatePosition() {
            let elem = document.querySelector('#chapter' + this.chapter.id).getBoundingClientRect()
            this.emitter.emit('chapter' + this.chapter.id, elem)
        }
    },
    data: function() {
        return {
            style: 'left: 10px'
        }
    },
    created() {
        window.addEventListener('resize', this.updatePosition);
    },
    unmounted() {
        window.removeEventListener('resize', this.updatePosition);
    },
    updated() {
        this.updatePosition()
    },
    mounted() {
        this.updatePosition()
    },
    computed: {
        classStyle: function() {
            return this.style
        }
    }
}
</script>

<style scoped>
@import '../assets/constants.css';

div {
    color: white;
    padding: 10px;
    border: 1px solid var(--dark-background-color);
    border-radius: 10px;
    /* position: absolute; */
    /* max-width: 200px; */
    background-color: var(--light-color-selection);
    height: auto;
    z-index: 1;
}
</style>
