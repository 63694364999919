<template>
<div>
    <div class="content">
        <h1>{{ title }}</h1>
        <p>{{ description }}</p>
        <tail-grid
            :type="'direction'"
            :root_path="'professions/' + $route.params.slug + '/'"
            :title="'Направления'"
        ></tail-grid>
    </div>
</div>
</template>

<script>
import SiteHeader from '@/components/SiteHeader.vue'
import TailGrid from '@/components/TailGrid.vue'
import router from "@/router/index.js"

export default {
    components: {
        SiteHeader,
        TailGrid
    },
    data: function () {
        return {
            title: '',
            description: ''
        }
    },
    mounted() {
        this.axios.get(`${this.site_url}/api/professions/${this.$route.params.slug}`).then(response => {
            this.title = response.data.title
            this.description = response.data.description
        }).catch(err => {
            router.push({name: 'page404', params: err})
        })
    }
}
</script>

<style scoped>

</style>
