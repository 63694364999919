<template>
    <div
        class="chapter"
        @click="showBlocks"
        :id="'chapter' + chapter.id"
        :style="style"
    >{{ chapter.title }}</div>
    <div
        class="blocks"
        v-if="show"
    >
        <block-item
            v-for="(block, index) in chapter.blocks"
            :key="'block' + block.id"
            :block="block"
            :index="index"
            :id="'block' + block.id"
            :redirect="redirect_blocks"
        ></block-item>
    </div>
</template>

<script>
import BlockItem from '@/components/BlockItem.vue'
export default {
  components: { BlockItem },
    name: 'chapter-item',
    props: ['chapter', 'index', 'row', 'redirect_blocks'],
    data: function() {
        return {
            show: false,
            style: ''
        }
    },
    methods: {
        showBlocks: function () {
            console.log(this.show)
            this.show = !this.show
            if (this.show) {
                this.style = 'background-color: var(--dark-background-color-hover)'
            } else {
                this.style = 'background-color: var(--dark-background-color)'
            }
        }
    }
}
</script>

<style scoped>
@import '../assets/constants.css';

.chapter {
    color: whitesmoke;
    padding: 10px;
    border: 1px solid whitesmoke;
    border-radius: 10px;
    background-color: var(--dark-background-color);
    height: auto;
    margin: 3px;
    cursor: pointer;
    transition: all 0.2s ease-out;
}
.blocks {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5px;
    padding: 10px;
    width: 100%;
}
</style>
